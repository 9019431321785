import communityRouter from "./module/communityRouter";
import customerRouter from "./module/customerRouter";
import equipmentRouter from "./module/equipmentRouter";
import homeRouter from "./module/homeRouter";
import indentRouter from "./module/indentRouter";
import moneyRouter from "./module/moneyRouter";
import organizationRouter from "./module/organizationRouter";
import SiteRouter from "./module/SiteRouter";
import SystemRouter from "./module/SystemRouter";
const EquipmentManagementRouter = {
    path: '/EquipmentManagement',
    name: 'EquipmentManagement',
    redirect: "/EquipmentManagement/home",
    component: () => import('@/views/EquipmentManagement'),
    meta: {
        title: "设备管理平台",
        icon: "el-icon-setting"
    },
    children: [
        homeRouter,
        moneyRouter,
        SiteRouter,
        equipmentRouter,
        organizationRouter,
        communityRouter,
        indentRouter,
        SystemRouter,
        customerRouter,
    ],
}

export default EquipmentManagementRouter;