const homeRouter = {
    path: 'home',
    name: 'home',
    component: () => import('@/views/EquipmentMonitoring/home/index.vue'),
    meta: {
        title: "首页",
        icon: "el-icon-house"
    },
    children: [

    ],
}

export default homeRouter;
        