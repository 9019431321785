const moneyRouter = {
    path: 'money',
    name: 'money',
    component: () => import('@/views/EquipmentManagement/money/index.vue'),
    redirect: "/EquipmentManagement/money/charge",
    meta: {
        title: "资金管理",
        icon: "el-icon-lollipop"
    },
    children: [
        {
            path: 'charge',
            name: 'charge',
            component: () => import('@/views/EquipmentManagement/money/charge/index.vue'),
            meta: {
                title: "充值管理",
                icon: "icon iconfont icona-zu13741"
            },
        }
    ],
}

export default moneyRouter;
        