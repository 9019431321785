// 本次自动写入时间：Tue Apr 12 2022 17:03:17 GMT+0800 (中国标准时间)

import echartsRouter from "./module/echartsRouter";
// 本次自动写入时间：Tue Apr 12 2022 16:59:36 GMT+0800 (中国标准时间)

import mapRouter from "./module/mapRouter";
import vuexRouter from "./module/vuexRouter";
import bubblyRouter from "./module/bubblyRouter";
const testRouter = {
    path: '/test',
    name: 'test',
    redirect: "/test/map",
    component: () => import('@/views/test'),
    meta: {
        title: "测试平台",
        icon: "el-icon-s-tools"
    },
    // noShow: true,
    children: [
        // NodeOne
        mapRouter,
        echartsRouter,
        vuexRouter,
        bubblyRouter,
    ],
}

export default testRouter;