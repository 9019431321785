<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "app",
  mounted() {
    // 监听页面刷新;
    window.onunload = (e) => {
      this.$store.commit("saveStateTolocal");
      // console.log("我刷新了");
      // e.preventDefault();
    };
  },
  created() {
    this.$store.commit("getlocalToState");
  },
  watch: {
    $route(newValue, oldValue) {
      console.log("路由监听", newValue, oldValue);
      this.$store.commit("setMenuDefaultActive",newValue.fullPath );
    },
  },
};
</script>
<style >
html,
body,
#app {
  width: 100%;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 1.2rem;
}
</style>
