const vuexRouter = {
    path: 'vuex',
    name: 'vuex',
    component: () => import('@/views/test/vuex/index.vue'),
    meta: {
        title: "vuex",
        icon: "el-icon-s-tools"
    },
    children: [],
}

export default vuexRouter;
        