const SystemRouter = {
    path: 'System',
    name: 'System',
    component: () => import('@/views/EquipmentManagement/System/index.vue'),
    meta: {
        title: "系统管理",
        icon: "el-icon-setting"
    },
    noShow: true,
    children: [],
}

export default SystemRouter;
        