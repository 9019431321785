import Vue from 'vue';
import VueRouter from 'vue-router';
import {
  Message,
  Loading
} from 'element-ui';
import EquipmentManagementRouter from "./module/EquipmentManagement"
import EquipmentMonitoringRouter from "./module/EquipmentMonitoring"
import testRouter from "./module/test"
Vue.use(VueRouter)
const routes = [{
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue'),
    meta: {
      title: "用户登录"
    },
  },
  {
    path: '/',
    name: 'home',
    redirect: "/SingleLogin",
    meta: {
      title: "首页"
    }
  },
  {
    path: '/SingleLogin',
    name: 'SingleLogin',
    component: () => import('@/views/SingleLogin/index.vue'),
    meta: {
      title: "单点登录"
    },
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404/index.vue'),
    meta: {
      title: "找不到网页"
    },
  },
  EquipmentManagementRouter,
  EquipmentMonitoringRouter,
  testRouter,
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 解决路由重复报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
  // console.log("前置路由守卫");
  // console.log(to, from);
  /* 联调 */
  let token = localStorage.token || "";
  let islockScreen = (sessionStorage.islockScreen ? JSON.parse(sessionStorage.islockScreen) : '') || false
  let router = (sessionStorage.router ? JSON.parse(sessionStorage.router) : "") || '/'
  if (token) {
    if (
      to.fullPath == "/login"
    ) {
      Message.warning("您已登陆，授权未过期！"), next(from.fullPath)
    } else {
      // 锁屏判断
      if (islockScreen) {
        to.fullPath == router ? next() : next(router)
      } else {
        next()
      }
    }
  } else {
    to.fullPath == "/login" ? next() : (
      // Message.warning("授权已过期，请重新登录！"),
      next("/login")
    )
  }
  /* 开发 */
  // next()
})
// router.afterEach((to, from) => {})

export default router