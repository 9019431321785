const indentRouter = {
    path: 'indent',
    name: 'indent',
    component: () => import('@/views/EquipmentManagement/indent/index.vue'),
    meta: {
        title: "订单管理",
        icon: "el-icon-odometer"
    },
    children: [],
}

export default indentRouter;
        