const communityRouter = {
    path: 'community',
    name: 'community',
    component: () => import('@/views/EquipmentManagement/community/index.vue'),
    meta: {
        title: "社区管理",
        icon: "el-icon-table-lamp"
    },
    noShow: true,
    children: [{
        path: 'community',
        name: 'community',
        component: () => import('@/views/EquipmentManagement/community/index.vue'),
        meta: {
            title: "社区管理",
            icon: "el-icon-table-lamp"
        },

    }, {
        path: 'community',
        name: 'community',
        component: () => import('@/views/EquipmentManagement/community/index.vue'),
        meta: {
            title: "社区管理",
            icon: "el-icon-table-lamp"
        },

    }],
}

export default communityRouter;