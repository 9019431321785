const SiteRouter = {
    path: 'Site',
    name: 'Site',
    component: () => import('@/views/EquipmentManagement/Site/index.vue'),
    meta: {
        title: "站点管理",
        icon: "el-icon-tableware"
    },
    children: [],
}

export default SiteRouter;
        