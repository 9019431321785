const echartsRouter = {
    path: 'echarts',
    name: 'echarts',
    component: () => import('@/views/test/echarts/index.vue'),
    meta: {
        title: "echarts",
        icon: "el-icon-s-tools"
    },
    children: [],
}

export default echartsRouter;
        