const customerRouter = {
    path: 'customer',
    name: 'customer',
    component: () => import('@/views/EquipmentManagement/customer/index.vue'),
    meta: {
        title: "用户管理",
        icon: "el-icon-present"
    },
    children: [],
}

export default customerRouter;
        