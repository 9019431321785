const bubblyRouter = {
    path: 'bubbly',
    name: 'bubbly',
    component: () => import('@/views/test/bubbly/index.vue'),
    meta: {
        title: "bubbly",
        icon: "el-icon-s-tools"
    },
    children: [],
}

export default bubblyRouter;
        