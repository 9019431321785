const equipmentRouter = {
    path: 'equipment',
    name: 'equipment',
    component: () => import('@/views/EquipmentManagement/equipment/index.vue'),
    meta: {
        title: "设备管理",
        icon: "el-icon-cpu"
    },
    children: [],
}

export default equipmentRouter;
        