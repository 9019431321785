import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 是否折叠菜单
    isActive: false,
    // 当前激活菜单的 index
    menuDefaultActive: "home",
    // 路由
    router: '',
    // 是否锁屏
    islockScreen: false,
    // 头像
    circleUrl:"https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
    // 用户名
    userName:""
  },
  mutations: {
    // 改变菜单折叠状态
    changIsActive(state) {
      state.isActive = !state.isActive
    },
    // 设置激活菜单
    setMenuDefaultActive(state, val) {
      state.menuDefaultActive = val
    },
    // 设置路由
    setRuoter(state, val) {
      state.router = val
    },
    // 设置锁屏状态 
    setlockScreen(state, val) {
      state.islockScreen = val
    },
    // 设置头像
    setCircleUrl(state, val) {
      state.circleUrl = val
    },
    // 设置用户名
    setUserName(state, val) {
      state.userName = val
    },
    // 保存vuex数据到sessionStorage
    saveStateTolocal(state) {
      Object.keys(state).length && Object.keys(state).forEach((key) => {
        sessionStorage[key] = JSON.stringify(state[key])
      })
    },
    // 从sessionStorage获取vuex值
    getlocalToState(state) {
      Object.keys(state).length && Object.keys(state).forEach((key) => {
        sessionStorage[key] && (state[key] = JSON.parse(sessionStorage[key]))
      })
    }

  },
  getters: {},
  actions: {},
  modules: {}
})