import Captcha from "captcha-mini";


/* 公共函数 */
/* 
 *   作用
 *   @parms：输入说明
 *   @reture ： 输出说明
 *   createTime：创建时间+姓名
 *   upTime:修改时间表+姓名
 */

/**
 * 千分号处理
 * @param  num 数值
 * @param symbol 间隔符号；
 * @return  str 转换后的字符；
 * createTime：2022年3月19日13:54:29 倪兵
 * upTime:
 */

export const formatNumberRgx = (num, symbol) => {
    !symbol ? symbol = "," : "";
    num.constructor !== Number ? (console.error(new Error("您的输入不是数值类型！"))) : ""
    var parts = num.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(symbol);
}



/**
 * 验证码生成器
 * @param  dom DOM元素必须是canvas
 * @param options 配置
 * @return  verification 验证码；
 * @return   captcha 实例；
 * createTime：2022年3月21日17:45:59 倪兵
 * upTime:
 */

export const drawVerification = (dom, options = {}) => {
    if (!dom) {
        return Error("未找到DOM对象！")
    }
    let verification = ""
    let captcha = new Captcha(options);
    captcha.draw(dom, (r) => {
        verification = r;
    });
    return {
        verification,
        captcha
    };
}


/**
 * 二进制流转成文件（img）
 * @param  data 二进制流
 * @param type 类型
 * @return  href 内存链接
 * createTime：2022年3月22日10:07:19 倪兵
 * upTime:
 */

export const flowTofile = (data, type) => {
    let blob = new Blob([data], {
        type: `application/${type};charset=utf-8`
    });
    // 创建链接
    let href = window.URL.createObjectURL(blob);
    return href
}



/**
 * 下载二进制流文件
 * @param  data 二进制流
 * @param type 类型
 * @param fileName 文件命
 * createTime：2022年3月22日10:07:19 倪兵
 * upTime:
 */

export const downloadFlowfile = (data, type, fileName) => {
    let blob = new Blob([data], {
        type: `application/${type};charset=utf-8`
    });
    let downloadElement = document.createElement('a');
    // 创建下载的链接
    let href = window.URL.createObjectURL(blob);
    downloadElement.href = href;
    // 下载后文件名
    downloadElement.download = fileName;
    document.body.appendChild(downloadElement);
    // 点击下载
    downloadElement.click();
    // 下载完成移除元素
    document.body.removeChild(downloadElement);
    // 释放掉blob对象
    window.URL.revokeObjectURL(href);
}



/**
 * 将base64转换为二进制
 * @param  dataurl base64
 * @return  u8arr 二进制
 * createTime：2022年3月22日10:07:19 倪兵
 * upTime:
 */
export const dataURLtoBlob = (dataurl) => {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    // return new Blob([u8arr], {
    //     type: mime
    // });
    return u8arr
}

/**
 * 将图片转换为base64
 * @param  img 二进制流图片
 * @return  dataURL base64字符
 * createTime：2022年3月22日10:07:19 倪兵
 * upTime:
 */

export const getBase64Image = (img) => {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height);
    var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
    var dataURL = canvas.toDataURL("image/" + ext);
    return dataURL;
}


/**
 * 将二进制流转换为base64
 * @param  getBlobStr 二进制流
 * @return  dataURL base64字符
 * createTime：2022年3月22日10:07:19 倪兵
 * upTime:
 */

export const blobToBase64 = (getBlobStr) => {
    return btoa(new Uint8Array(getBlobStr).reduce((data, byte) => data + String.fromCharCode(byte), ''))
}


/**
 * 监听按键
 * @param  options 监听配置
 * @param  options.key 键码
 * @param  options.fn 回调函数
 * createTime：2022年3月22日10:07:19 倪兵
 * upTime:
 */

export const listenKey = (options = []) => {
    if (!options && options.length) return
    document.onkeydown = function (e) {
        //对整个页面监听
        var keyNum = window.event ? e.keyCode : e.which; //获取被按下的键值
        let key = options.filter((e) => {
            return e.key == keyNum
        })
        // console.log(key,keyNum);
        key[0].fn()
    }
}



/**
 * 非递归查询树的数据
 * @param  tree 树
 * @param  key 搜索值
 * @param  children 子节点属性
 * @param  Attribute 查询的属性
 * @return  temp 找到的数据
 * createTime：2022年3月22日10:07:19 倪兵
 * upTime:
 */
export const deepQuery = (tree, key ,children="children",Attribute="id") => {
    var stark = [];
    stark = stark.concat(tree);
    while (stark.length) {
        var temp = stark.shift();
        if (temp[children]) {
            stark = temp[children].concat(stark);
        }
        if (key === temp[Attribute]) {
            return temp;
        }
    }
}