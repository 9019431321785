import homeRouter from "./module/homeRouter";
const EquipmentMonitoringRouter = {
    path: '/EquipmentMonitoring',
    name: 'EquipmentMonitoring',
    redirect: "/EquipmentMonitoring/home",
    component: () => import('@/views/EquipmentMonitoring'),
    meta: {
        title: "设备监控平台",
        icon: "el-icon-setting"
    },
    children: [
        homeRouter,
    ],
}

export default EquipmentMonitoringRouter;