const mapRouter = {
    path: 'map',
    name: 'map',
    component: () => import('@/views/test/map/index.vue'),
    meta: {
        title: "map",
        icon: "el-icon-s-tools"
    },
    children: [],
}

export default mapRouter;
        