import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/style/index.scss"
Vue.use(ElementUI);
// 粒子背景组件
import VueParticles from 'vue-particles'
Vue.use(VueParticles)

// 阿里图标
import "./assets/fonts/iconfont.css"
import "./assets/fonts/iconfont"

// 注入公共工具
import * as baseFunction from "./common/uitls/base"
Object.keys(baseFunction).forEach((key) => {
  Vue.prototype[key] = baseFunction[key]
})



// // 注入地图
// import VueAMap from "vue-amap";
// Vue.use(VueAMap);
// // 初始化高德地图的 key 和插件
// VueAMap.initAMapApiLoader({
//   key: "201c96148fd00533dadd185d5c3f2d8d",
//   plugin: [
//     'AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.Geolocation', 'AMap.Geocoder', 'AMap.AMapManager', 'AMap.Marker'
//   ],
//   v: "1.4.4",
// });


// 注入地图
import AmapVue from '@amap/amap-vue';

AmapVue.config.version = '2.0'; // 默认2.0，这里可以不修改
AmapVue.config.key = '201c96148fd00533dadd185d5c3f2d8d';
AmapVue.config.plugins = [
  // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
  'AMap.ToolBar',
  'AMap.MoveAnimation',
  'AMap.Autocomplete',
  'AMap.PlaceSearch',
  'AMap.Scale',
  'AMap.OverView',
  'AMap.MapType',
  'AMap.Geolocation',
  'AMap.Geocoder',
  'AMap.AMapManager',
  'AMap.Marker',
  'AMap.DistrictSearch',
  'AMap.CitySearch'
];
Vue.use(AmapVue);

// 设置rem
import setRem from "./common/uitls/setRem"
setRem()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')