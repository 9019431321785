const organizationRouter = {
    path: 'organization',
    name: 'organization',
    component: () => import('@/views/EquipmentManagement/organization/index.vue'),
    meta: {
        title: "组织管理",
        icon: "el-icon-ice-cream-square"
    },
    noShow: true,
    children: [],
}

export default organizationRouter;
        